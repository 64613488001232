body {
  margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

@font-face {
  font-family: 'Corsica MX Medium Cond';
  src: local('CorsicaMXMediumCond'),
    url('../public/fonts/CorsicaMXMediumCond.otf') format('opentype');
}

@font-face {
  font-family: 'Corsica LX Bold';
  src: local('CorsicaLXBold'), url('../public/fonts/CorsicaLXBold.otf') format('opentype');
}

@font-face {
  font-family: 'Gothic Bold';
  src: local('GothicBold'), url('../public/fonts/GOTHICB.TTF') format('opentype');
}

@font-face {
  font-family: 'Gothic';
  src: local('Gothic'), url('../public/fonts/GOTHIC_0.TTF') format('opentype');
}

@font-face {
  font-family: 'Arial Bold';
  src: local('Gothic'), url('../public/fonts/ARIALBD.TTF') format('opentype');
}
